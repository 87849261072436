import { Component, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as axios from 'axios/dist/axios.min.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  barcodeScanned = new Subject<string>();
  cameraScan = true;
  parcelReference
  shipment;
  stageSucess = false;
  cameras;
  error;

  @ViewChild('input', { static: true }) input;

  stagingUrl = 'https://picupstaging-webapi.azurewebsites.net/v1/'
  productionUrl = 'https://picupprod-webapi.azurewebsites.net/v1/';
  OTDCLstagingKey = 'business-d4835642-98d6-40b6-a81e-bc6637c4eb6d';
  OTDCLproductionKey = 'business-16b8e0ae-8162-4ef6-9a81-138e6f4392ba';
  OTDwarehouseId = 'warehouse-3a6148a2-70d1-4ca2-8e06-1229e5a0c040'
  shiftStart = '09:00'
  shiftEnd = '17:00'
  client = 'dischem' //dischem/otd/etc
  isStaging = false;

  url;
  apikey;
  constructor() {
    this.barcodeScanned.pipe(
      debounceTime(400))
      .subscribe(value => {
        this.scan(value);
      });

    this.OTDCLproductionKey;

    if (this.isStaging) {
      axios.defaults.baseURL = this.stagingUrl
    } else {
      axios.defaults.baseURL = this.productionUrl;
    }

    if (this.client === 'dischem') {
      if (this.isStaging) {
        axios.defaults.headers.common['api-key'] = 'business-01730b76-5324-4c2a-9518-306f1ebaf27b'
      } else {
        axios.defaults.headers.common['api-key'] = 'business-8944f769-bdba-4496-85b3-085c0ce16959'
      }

    } else if (this.client === 'otd') {
      if (this.isStaging) {
        axios.defaults.headers.common['api-key'] = 'business-d4835642-98d6-40b6-a81e-bc6637c4eb6d'
      } else {
        axios.defaults.headers.common['api-key'] = 'business-8b1f02b1-69db-424d-8e5c-4b227182ce5e'
      }
    }
  }

  camerasFoundHandler($event) {
    console.log($event);
    // this.cameras= $event;
  }

  printWaybill(waybillNumbers) {
    return axios({
      method: 'POST',
      url: '/integration/download-waybills',
      responseType: 'arraybuffer',
      data: waybillNumbers
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const blobURL = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobURL;
        document.body.appendChild(iframe);

        setTimeout(
          function () {
            iframe.contentWindow.print();
          }, 1000);

        this.reset();


      })
  }

  printWaybillsbyParcelReference(waybillNumbers) {
    const number = waybillNumbers[0];
    return axios({
      method: 'GET',
      url: 'integration/download-waybills-by-parcel-reference/' + number,
      responseType: 'arraybuffer',
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const blobURL = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobURL;
        document.body.appendChild(iframe);

        setTimeout(
          function () {
            iframe.contentWindow.print();
          }, 1000);

        this.reset();
      })
  }



  searchLookups(orderLookup) {
    this.error = null;
    return axios
      .get(`/integration/order-by-business-reference/` + orderLookup)
      .then(
        response => {
          this.shipment = true;
          this.shipment = response.data;
        })
      .catch(
        error => {
          if (error.response.data.message) {
            this.error = error.response.data.message;
          }
          this.reset();
        }
      )
  }

  scan(reference) {
    // FOR PRINTING WAYBILLS by PARCEL REF
    // this.printWaybillsbyParcelReference([reference]);

    // FOR PRINTING WAYBILLS PCW
    this.printWaybill([reference]);

    // FOR LOOKING UP ROUTE NUMBER
    // this.searchLookups(reference);

    //FOR RECIEVING STAGED ORDERS
    // this.recieveStagedOrder(reference);
  }

  recieveStagedOrder(parcelreference) {
    var date = new Date();
    date.setDate(date.getDate() + 1);
    axios
      .get(`/integration/order-by-business-reference/` + parcelreference)
      .then(
        response => {
          const bucketOrderid = '253900'
          return axios({
            method: 'POST',
            url: '/integration/receive-orders',
            data:
            {
              "business_id": this.OTDCLproductionKey,
              "warehouse_id": this.OTDwarehouseId,
              "delivery_date": date,
              "shift_start": this.shiftStart,
              "shift_end": this.shiftEnd,
              "bucket_order_ids": [
                bucketOrderid
              ]
            }
          })
            .then(
              () => this.stageSucess = true
            )
            .catch(
              error => {
                if (error.response.data.message) {
                  this.error = error.response.data.message;
                }
                this.reset();
              }
            )
        })
      .catch(
        error => {
          if (error.response.data.message) {
            this.error = error.response.data.message;
          }
          this.reset();
        }
      )
  }


  reset() {
    this.shipment = undefined;
    this.stageSucess = false;
    this.parcelReference = null;
    this.input.nativeElement.value = null;
    this.input.nativeElement.focus();
  }

  ngAfterViewInit() {
    this.input.nativeElement.focus();
  }

}

